import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// import { Link } from "react-router-dom";

import Button from "components/CustomButtons/Button.js";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

export default function ProductSection() {
  const useStyles = makeStyles(styles);

  // var end = document.getElementById("end").innerText;

  function handleSubmit(e) {
    e.preventDefault();
    var XMLHttpRequest = require("xhr2");
    var xhr = new XMLHttpRequest();
    var start = document.getElementById("start-in").value;
    if (start == null) {
      alert("star: More information required");
      return true;
    }
    var end = document.getElementById("end-in").value;
    if (end == null) {
      alert("end : More information required");
      return true;
    }
    var campground = document.getElementById("campground-in").value;
    if (campground == null) {
      alert("campground : More information required");
      return true;
    }
    var state = document.getElementById("states").value;
    if (state == null) {
      alert("state : More information required");
      return true;
    }
    var campsite = document.getElementById("campsite-in").value;
    if (campsite == null) {
      alert("campsite : More information required");
      return true;
    }
    var phone = document.getElementById("phone-in").value;
    if (phone == null) {
      alert("phone : More information required");
      return true;
    }
    // var phoneFormatted = document.getElementById("phone-formatted").value;
    // alert(phoneFormatted);
    // alert(start);
    let startYear = start.substr(0, 4);
    let startMonth = start.substr(5, 2);
    let startDay = start.substr(8, 2);
    let endYear = end.substr(0, 4);
    let endMonth = end.substr(5, 2);
    let endDay = end.substr(8, 2);

    // alert("start year is: " + startYear);
    // alert("start day is: " + startDay);
    // alert("start month is: " + startMonth);
    // alert("end year is: " + endYear);
    // alert("end day is: " + endDay);
    // alert("end month is: " + endMonth);

    if (parseInt(endYear) < parseInt(startYear)) {
      alert("EXCUSE ME your end date year is behind your start date year???");
      return true;
    }
    if (parseInt(endMonth) < parseInt(startMonth)) {
      alert("EXCUSE ME your end date month is behind your start date month???");
      return true;
    }
    if (parseInt(endDay) < parseInt(startDay)) {
      alert("EXCUSE ME your end date day is behind your start date day???");
      return true;
    }
    // console.log("start date is: " + start);
    // console.log("end date is: " + end);
    var userID = localStorage.getItem("userID");
    campground = campground + " " + state;
    // alert(campground);
    var API_CALL =
      "http://127.0.0.1:5000/job?campground=" +
      campground +
      "&campsite=" +
      campsite +
      "&startDate=" +
      start +
      "&endDate=" +
      end +
      "&phone=" +
      phone +
      "&userID=" +
      userID;
    // alert(API_CALL);
    xhr.open("POST", API_CALL, true);
    xhr.send();
    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        // alert(JSON.stringify(xhr.responseText));
        // console.log(JSON.stringify(xhr.responseText));
        window.location.replace("http://localhost:3000/" + "job-initiated");
      }
    };
  }
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <input
            type="date"
            id="start-in"
            placeholder="Start Date (yyyy/mm/dd)"
            required
          />
          <br />
          <br />
          <br />
          <input
            type="date"
            id="end-in"
            placeholder="End Date (yyyy/mm/dd)"
            required
          />
          <br />
          <br />
          <br />
          <input
            type="text"
            id="campground-in"
            placeholder="Campground"
            required
          />
          <br />
          <label htmlFor="">Exact Spelling Required</label>
          <br />
          <br />
          <label htmlFor="">Campground State</label>
          <br />
          <select id="states" name="States" required>
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">Arkansas</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
          </select>
          <br />
          <br />
          <br />
          <input type="text" id="campsite-in" placeholder="Campsite" required />
          <br />
          <br />
          <br />
          <input
            type="text"
            id="phone-in"
            placeholder="Phone (no dashes)"
            required
          />
          <br />
          <br />
          <br />
          {/* <input
            type="tel"
            id="phone-formatted"
            name="phone"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            required
          />
          <br />
          <br />
          <br /> */}
          <form onSubmit={handleSubmit}>
            {/* <Link to="/job-initiated"> */}
            <Button type="submit" id="send-btn">
              Send
            </Button>
            {/* </Link> */}
          </form>
          <br />
          <br />
          <br />
          <label id="response-lbl"></label>
          <br />
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
