import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/bg7.jpg";
const useStyles = makeStyles(styles);
export default function LoginPage(props) {
  function handleSubmit(e) {
    e.preventDefault();
    var XMLHttpRequest = require("xhr2");
    var xhr = new XMLHttpRequest();
    var username = document.getElementById("username-in").value.trim();
    var password = document.getElementById("password-in").value.trim();
    var prompt = document.getElementById("prompt");
    alert(username);
    alert(password);
    // alert(responseLabel);
    var API_CALL =
      "http://127.0.0.1:5000/userValidation?username=" +
      username +
      "&password=" +
      password;
    alert(API_CALL);
    xhr.open("GET", API_CALL, true);
    xhr.send();
    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        // alert(JSON.stringify(xhr.responseText));
        // console.log(JSON.stringify(xhr.responseText));
        alert(xhr.responseText);
        var data = xhr.responseText;
        var jsonResponse = JSON.parse(data);
        // console.log(jsonResponse);
        var message = jsonResponse["message"];
        alert(message);
        // console.log(message);
        if (message == "proceed") {
          alert("we are good to add this user");
          var API_CALL =
            "http://127.0.0.1:5000/user?username=" +
            username +
            "&password=" +
            password;
          // alert(API_CALL);
          xhr.open("POST", API_CALL, true);
          xhr.send();
          xhr.onreadystatechange = function () {
            if (xhr.readyState == XMLHttpRequest.DONE) {
              //here we need to get the userID and save it into lcoal storage for identificaiton
              alert(xhr.responseText);
              // var data = xhr.responseText;
              // var jsonResponse = JSON.parse(data);

              alert("new user has beeen added to the database");
              getUserID(username, password);
            }
          };
        }
        if (message == "halt") {
          prompt.removeAttribute("hidden");
        }
      }
    };
  }
  function handleLogin(e) {
    e.preventDefault();
    var XMLHttpRequest = require("xhr2");
    var xhr = new XMLHttpRequest();
    var username = document.getElementById("username-in").value.trim();
    var password = document.getElementById("password-in").value.trim();
    // alert(username);
    // alert(password);
    // alert(responseLabel);
    var API_CALL =
      "http://127.0.0.1:5000/userValidation?username=" +
      username +
      "&password=" +
      password;
    // alert(API_CALL);
    xhr.open("GET", API_CALL, true);
    xhr.send();
    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        // alert(JSON.stringify(xhr.responseText));
        // console.log(JSON.stringify(xhr.responseText));
        // alert(xhr.responseText);
        var data = xhr.responseText;
        var jsonResponse = JSON.parse(data);
        // console.log(jsonResponse);
        var message = jsonResponse["message"];
        // console.log(message);
        if (message == "halt") {
          // alert(
          //   "we are good to login this user since their credentials exist and are matching therefore throwing a halt"
          // );
          getUserID(username, password);
        }
      }
    };
  }
  function getUserID(username, password) {
    var XMLHttpRequest = require("xhr2");
    var xhr = new XMLHttpRequest();
    var API_CALL =
      "http://127.0.0.1:5000/user?username=" +
      username +
      "&password=" +
      password;
    // alert(API_CALL);

    xhr.open("GET", API_CALL, true);
    xhr.send();
    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        // alert(JSON.stringify(xhr.responseText));
        // console.log(JSON.stringify(xhr.responseText));
        // alert(xhr.responseText);
        var data = xhr.responseText;
        var jsonResponse = JSON.parse(data);
        // console.log(jsonResponse);
        var message = jsonResponse["message"];
        console.log(message);
        localStorage.setItem("userID", message);
        var userID = localStorage.getItem("userID");
        console.log(userID);
        // alert("user ID in local storage is: " + userID);
        if (localStorage.getItem("userID") != null) {
          // alert(
          //   "we confirmed that the userID attribute is populated within local storage"
          // );
          window.location.replace("http://localhost:3000/" + "profile-page");
        }
      }
    };
  }
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Camp Scout"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <input type="text" id="username-in" placeholder="Username..." />
          <input type="text" id="password-in" placeholder="Password..." />
          <br />
          <br />

          <form onSubmit={handleLogin}>
            <button type="submit" simple color="primary" size="lg">
              Login
            </button>
            {/* </Link> */}
          </form>
          <br />
          <br />
          <br />
          <form onSubmit={handleSubmit}>
            <button type="submit" simple color="primary" size="lg">
              First time? Get started here.
            </button>
            {/* </Link> */}
          </form>
          <label id="prompt" hidden>
            This account already exists.
          </label>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
