import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import People from "@material-ui/icons/People";
import Favorite from "@material-ui/icons/Favorite";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
// import Nature from "@material-ui/icons/Map.js";

import profile from "assets/img/faces/avatar.jpg";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  // function reply_click(clicked_id) {
  //   alert(clicked_id);
  // }
  function PopulateJobLbls() {
    // alert("We are now populating Jobs");
    // var jobs = document.getElementById("jobs-lbls").value;
    //call the API here and retrieve all jobs belonging to te user from Mongodb
    var userID = localStorage.getItem("userID");
    // alert(userID);
    var XMLHttpRequest = require("xhr2");
    var xhr = new XMLHttpRequest();
    var API_CALL = "http://127.0.0.1:5000/job?userID=" + userID;
    xhr.open("GET", API_CALL, true);
    xhr.send();

    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        // alert(JSON.stringify(xhr.responseText));
        // console.log(JSON.stringify(xhr.responseText));

        // alert(xhr.responseText);
        console.log(xhr.responseText);
        var data = xhr.responseText;
        var jsonResponse = JSON.parse(data);
        var jobObjectArray = jsonResponse["userJobs"];
        console.log(
          "the length of the job array is : " + jobObjectArray.length
        );

        let counter = 1;
        for (let i = 0; i < jobObjectArray.length; i++) {
          console.log("pass# " + counter.toString());
          let currentCampgroundText =
            jobObjectArray[i]["Campground"] +
            " " +
            jobObjectArray[i]["Campsite"];
          let lblIndex = "job" + counter.toString();
          console.log("lbl index is: " + lblIndex);
          let buttonIndex = "btn" + counter.toString();
          console.log("lbl index is: " + buttonIndex);

          let lblInQuestion = document.getElementById(lblIndex);
          let buttonInQuestion = document.getElementById(buttonIndex);
          lblInQuestion.innerText = currentCampgroundText;
          buttonInQuestion.removeAttribute("hidden");
          // ul.children[i].textContent = currentCampground;
          // ul.children[i].removeAttribute("hidden");

          //   let currentJobLbl = document.getElementById(
          //     "job" + counter.toString()
          //   );
          //   currentJobLbl.removeAttribute("hidden");
          //   currentJobLbl.textContent =
          //     jobObjectArray[i]["Campground"] +
          //     " " +
          //     jobObjectArray[i]["Campsite"];
          counter++;
        }
        //loop through the JSON and make a li for each cell in the array
        //on each pass of the loop, also extract the string within each array cell into a text node
        //this text node is then added to the li tag immediately after it is created
        //proceed until the end of the jobs array is complete
      }
    };
  }
  function checkForID() {
    // await new Promise((r) => setTimeout(r, 5000));
    console.log("check for ID has been launched");
    PopulateJobLbls();
  }
  // alert(sessionStorage.getItem("userID"));
  window.onload = checkForID;

  return (
    <div>
      <Header
        color="transparent"
        brand="Camp Scout"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />

      <Parallax small filter image={require("assets/img/bg.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <label>Active Searches</label>
          <br />
          <br />
          <br />
          <p id="job1"></p>
          <button onClick="reply_click(this.id)" id="btn1" hidden>
            X
          </button>
          <br />
          <p id="job2"></p>
          <button onClick="reply_click(this.id)" id="btn2" hidden>
            X
          </button>
          <br />
          <p id="job3"></p>
          <button onClick="reply_click(this.id)" id="btn3" hidden>
            X
          </button>
          <br />
          <p id="job4"></p>
          <button onClick="reply_click(this.id)" id="btn4" hidden>
            X
          </button>
          <br />
          <p id="job5"></p>
          <button onClick="reply_click(this.id)" id="btn5" hidden>
            X
          </button>
          <br />

          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>John Adkerson</h3>
                    <h6>Hardcore Camper</h6>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>Insert Bio here...</p>
            </div>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Photos",
                      tabIcon: Camera,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio2}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio5}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio4}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "Friends",
                      tabIcon: People,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work5}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "Bookmarks",
                      tabIcon: Favorite,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    // {
                    //   tabButton: "My Searches",
                    //   tabIcon: Nature,
                    //   tabContent: (
                    //     <GridContainer justify="center">
                    //       <GridItem
                    //         xs={12}
                    //         sm={12}
                    //         md={8}
                    //         className={classes.navWrapper}
                    //       ></GridItem>
                    //     </GridContainer>
                    //   ),
                    // },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
