import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <CustomInput
            labelText="Enter campgrround name"
            id="float"
            formControlProps={{
              fullWidth: true,
            }}
          />
          <br />
          <Link to="/job-page">
            <Button color="success">Submit</Button>
          </Link>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer></GridContainer>
      </div>
    </div>
  );
}
